$(function() {
    var carousels = $('.logo-carousel-wrapper .logo-carousel');
    var prev;
    var next;
    var visible;

    if (carousels.find('.logo-carousel-cell').length < 2) {
        return;
    }

    visible = function() {
        var width = $(window).width();
        var breakpoints = {
            1: 420,
            2: 640,
            3: 960,
            4: 1280,
        };

        for (var key in breakpoints) {
            if (width < breakpoints[key]) {
                return key;
            }
        }

        return 4;
    };

    prev = $('.carousel-prev');
    next = $('.carousel-next');

    prev.on('click', function(e){
        e.preventDefault();
        e.stopPropagation();
    });

    next.on('click', function(e){
        e.preventDefault();
        e.stopPropagation();
    });

    carousels.each(function(i, element) {
        var carousel = $(element);

        // Function to initialize the carousel effect, using the visible
        // function to set the number of items visible based on the screen size.
        // The carousel effect requires the Cycle 2 Carousel plugin.
        var init = function() {
            carousel.cycle({
                slides: '> .logo-carousel-cell',
                fx: 'carousel',
                autoHeight: 'calc',
                carouselVisible: visible(),
                carouselFluid: true,
                prev: $('.carousel-prev'),
                next: $('.carousel-next'),
                log: true
            });
        };

        // Start the carousel on page load
        init();

        // It is impossible to change the carousel settings individually on the
        // fly, so this destroys and recreates the carousel from scratch when
        // the window has finished resizing.
        $(window).on('resizeDone', function() {
            carousel.cycle('destroy');
            init();
        });
    });
});
