/**
 * Trigger custom event when finished resizing
 *
 * Prevents stupid browsers from sending the same event every nanosecond when
 * all you need to know is whether the window has changed size or orientation.
 */
(function() {
    var timer;

    $(window).on('resize', function() {
        window.clearTimeout(timer);

        timer = window.setTimeout(function() {
            $(window).trigger('resizeDone');
        }, 50);
    });
})();
